import { inject, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CrmUrlParamsService } from 'common-module/core';
import { CrmDictionary } from 'common-module/core/types';
import { cloneDeep, isEmpty } from 'lodash-es';
import { DateTime } from 'luxon';
import {
  map,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { merge } from 'ts-deepmerge';

import { isBooleanString, parseBooleanString } from '../utils/boolean';

export abstract class ProvidedProvider<
  Config extends CrmDictionary,
  Data extends CrmDictionary,
> {
  readonly loading = signal(true);
  readonly config = signal<Config>({} as Config);
  readonly data = signal<Data>({} as Data);
  readonly afterInit$ = new Subject<void>();

  protected readonly destroy$ = new Subject<void>();
  protected readonly reload$ = new Subject<CrmDictionary | undefined>();

  protected route!: ActivatedRoute | null;
  protected persistUrlParams = true;
  protected readonly urlParamsService = inject(CrmUrlParamsService);

  protected readonly viewKey: string = 'provider';

  init(options: { route: ActivatedRoute | null }) {
    this.beforeInit();

    const { route } = options;

    this.reload$
      .pipe(
        switchMap((data) => this.loadData(data)),
        takeUntil(this.destroy$),
      )
      .subscribe((data) => {
        this.data.set(
          merge.withOptions({ mergeArrays: false }, this.data(), data) as Data,
        );
      });

    return this.initData(route).pipe(
      switchMap(() => this.getConfig()),
      tap((config) => {
        this.config.set(config);
        this.afterInit$.next();
        this.reload(this.data());
      }),
    );
  }

  onDestroy() {
    if (this.persistUrlParams) {
      this.urlParamsService.clearParams(this.viewKey);
    }

    this.data.set({} as Data);
    this.destroy$.next();
  }

  reload(data?: CrmDictionary) {
    this.reload$.next(data);
  }

  serializeQueryParams(params?: CrmDictionary): CrmDictionary {
    return this.serializeQueryParamsFn(params);
  }

  deserializeQueryParams(params: CrmDictionary): CrmDictionary {
    const transformed = cloneDeep(params);
    const { filter = {} } = transformed;

    Object.entries(filter).reduce((result, [key, value]) => {
      result[key] = this.deserializeQueryParamValue(value);
      return result;
    }, filter);

    return cloneDeep(transformed);
  }

  protected deserializeQueryParamValue(value: unknown): unknown {
    if (isBooleanString(value)) {
      return parseBooleanString(value);
    }

    return value;
  }

  protected serializeQueryParamValue(value: unknown): unknown {
    if (value instanceof Date) {
      return value.toISOString();
    }

    if (value instanceof DateTime) {
      return value.toISO();
    }

    if (Array.isArray(value)) {
      return value.map(this.serializeQueryParamValue).join(',');
    }

    if (typeof value === 'boolean') {
      return String(value);
    }

    return value;
  }

  protected beforeInit() {}

  protected wrapToLoading<T>(fn$: () => Observable<T>) {
    this.loading.set(true);
    return fn$().pipe(
      takeUntil(this.destroy$),
      tap(() => this.loading.set(false)),
    );
  }

  protected loadData(data?: CrmDictionary): Observable<Data> {
    return this.wrapToLoading(() => {
      this.updateUrlParams(data);
      return this.loadDataFn(data);
    });
  }

  protected initData(route: ActivatedRoute | null): Observable<CrmDictionary> {
    this.route = route;

    if (!this.route) {
      return of({});
    }

    return this.getDataFromQueryParams().pipe(
      tap((data) =>
        this.data.set(
          merge.withOptions({ mergeArrays: false }, this.data(), data) as Data,
        ),
      ),
    );
  }

  protected getDataFromQueryParams(): Observable<CrmDictionary> {
    if (!this.route) {
      throw new Error(`Route is not defined for ${this.constructor.name}`);
    }

    return this.route.queryParams.pipe(
      take(1),
      map((query) => this.decodeQueryParams(query)),
      switchMap((data) => this.resolveDataFromQueryParams(data)),
      tap((data) => this.afterResolveDataFromQueryParams(data)),
    );
  }

  protected resolveDataFromQueryParams(
    _: CrmDictionary,
  ): Observable<CrmDictionary> {
    return of({});
  }

  protected afterResolveDataFromQueryParams(_: CrmDictionary) {}

  protected decodeQueryParams(params: CrmDictionary): CrmDictionary {
    return this.deserializeQueryParams(
      this.urlParamsService.decodeParams(
        this.viewKey,
        !isEmpty(params) ? params : {},
      ) ?? {},
    );
  }

  protected updateUrlParams(data?: CrmDictionary): void {
    if (this.persistUrlParams) {
      this.urlParamsService.updateContextParams(
        this.viewKey,
        this.serializeQueryParams(this.getDataToStore(data)),
      );
    }
  }

  protected serializeQueryParamsFn(params?: CrmDictionary): CrmDictionary {
    if (!params) {
      return {};
    }

    return Object.entries(cloneDeep(params)).reduce((result, [key, value]) => {
      result[key] = this.serializeQueryParamValue(value);
      return result;
    }, {} as CrmDictionary);
  }

  protected getDataToStore(_?: CrmDictionary): CrmDictionary {
    return {};
  }

  protected abstract loadDataFn(data?: CrmDictionary): Observable<Data>;
  protected abstract getConfig(): Observable<Config>;
}
